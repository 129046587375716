<template>
  <b-carousel
    v-if="banners.length > 0"
    v-model="slide"
    :interval="10000"
    indicators
    class="cc"
  >
    <b-carousel-slide v-for="(b, i) in banners" v-bind:key="i">
      <template v-slot:img>
        <div class="card card-custom gutter-b" style="padding-top: 30%">
          <div class="d-flex" style="position: absolute; top: 0; left: 0;right: 0; bottom: 0">
            <div class="flex-grow-1 d-flex align-items-end card-rounded bgi-no-repeat banner" v-bind:style="bannerbackground(b)">
              <div class="p-6 overlay" v-if="showoverlay(b)">
                <h4 class="font-weight-bolder" :style="titlecolor(b)">{{ b.title }}</h4>
                <p class="my-5 font-size-xl font-weight-bold" :style="desccolor(b)">{{ b.desc }}</p>
                <a v-if="b.label !== ''" v-bind:href="b.link" class="btn font-weight-bold py-2 px-6" :style="buttoncolor(b)">{{ b.label }}</a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  data () {
    return {
      slide: 0
    }
  },
  props: {
    banners: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    bannerbackground(banner) {
      if (banner.img !== '') {
        return {
          'background-image': `url(${banner.img})`
        }
      }

      return {}
    },
    showoverlay(banner) {
      return (banner.title !== '' || banner.desc !== '' || banner.label !== '')
    },
    titlecolor(banner){
      return {
        color: banner.titlecolor != null && banner.titlecolor ? banner.titlecolor : '#FFFFFF'
      }
    },
    desccolor(banner) {
      return {
        color: banner.desccolor != null && banner.desccolor ? banner.desccolor : '#E0E0E0'
      }
    },
    buttoncolor(banner) {
      return {
        'background-color': banner.buttoncolor != null && banner.buttoncolor ? banner.buttoncolor : '#FF0000',
        color: banner.labelcolor != null && banner.labelcolor ? banner.labelcolor : '#FFFFFF'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.banner {
  background-color: #1d212a;
  background-position: center center;
  background-size: auto 100%;
}

@media (max-width: 991px) {
  .cc {
    margin: -15px -15px 0 -15px;
  }
}
</style>>
