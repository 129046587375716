<template>
  <div>
    <!-- Carousel -->
    <Carousel :banners="banners" />
    <!-- Match Info -->
    <div class="card card-custom card-stretch mt-4">
      <div class="card-body py-2">
        <div class="row">
          <div class="col-12 col-sm-6 px-4 pt-4">
            <div class="d-inline-flex align-items-center">
              <div class="d-flex">
                <div class="symbol symbol-20 symbol-dark mr-2 flex-shrink-0 text-center">
                  <div class="symbol-label">
                    <img v-if="match.game.img" :src="match.game.img" alt="" class="h-100 w-100" />
                  </div>
                </div>
                <div class="symbol symbol-20 symbol-dark mr-2 flex-shrink-0 text-center">
                  <div class="symbol-label">
                    <img v-if="match.tour.img" :src="match.tour.img" alt="" class="h-100 w-100" />
                  </div>
                </div>
              </div>
              <div class="">{{ match.tour.name }}</div>
            </div>
            <div class="h6 mt-1">{{ match.name }}</div>
            <div class="text-dark-50">{{ match.meta }}</div>
          </div>
          <div class="col-12 col-sm-6 px-4 pt-4 text-right pb-4">
            <div>เวลาแข่งตามกำหนด: <span class="font-weight-bolder">{{ date(match.plan) }}</span> - {{ time(match.plan) }}</div>
            <div class="text-info mb-4">เวลาปิดการทาย: <span class="font-weight-bold">{{ date(match.time) }}</span> - {{ time(match.time) }}</div>
            <div v-if="match.forfeit"><b-badge variant="danger">Forfeit</b-badge></div>
            <div v-else-if="match.ended"><b-badge variant="primary">Ended</b-badge></div>
            <div v-else-if="playing(match)"><b-badge variant="info">Playing</b-badge></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Player Embed -->
    <div v-if="embed" class="card card-custom card-stretch mt-2">
      <div class="card-body pt-4">
        <div class="font-size-h4 pb-2">Watch Stream</div>
        <div class="videoWrapper">
          <iframe width="560" height="315" :src="match.embed.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <!-- Result -->
    <div v-if="match.ended" class="card card-custom card-stretch mt-2">
      <div class="card-body px-4 pt-12 pb-2">
        <!-- Versus mode -->
        <b-row align-v="center" v-if="modevs(match)" class="mb-10">
          <b-col :cols="3" class="text-right font-size-h1 display-3 font-weight-bolder pr-10">{{ match.teams[0].score }}</b-col>
          <b-col :cols="6" class="">
            <b-row align-h="between" align-v="center">
              <div class="symbol symbol-30 symbol-lg-50">
                <div class="symbol-label bg-transparent">
                  <img :src="match.teams[0].img" alt="" class="h-100 w-100" />
                </div>
              </div>
              <b-col cols="auto" class="text-center">
                <div class="h6">{{ match.teams[0].name }}</div>
                <div>vs</div>
                <div class="h6 mt-4">{{ match.teams[1].name }}</div>
              </b-col>
              <div class="symbol symbol-30 symbol-lg-50">
                <div class="symbol-label bg-transparent">
                  <img :src="match.teams[1].img" alt="" class="h-100 w-100" />
                </div>
              </div>
            </b-row>
          </b-col>
          <b-col :cols="3" class="font-size-h1 display-3 font-weight-bolder pl-10">{{ match.teams[1].score }}</b-col>
        </b-row>
        <!-- Free for all mode -->
        <div v-if="modeffa(match)" class="mb-5">
          <b-row>
            <!-- First half -->
            <b-col :cols="12" :md="6">
              <b-row align-v="center" v-for="(t, i) in firsthalf(match)" v-bind:key="i">
                <b-col :cols="5" class="text-right font-size-h4 display-4 font-weight-bolder">
                  {{ t.score }}
                </b-col>
                <b-col :cols="1" class="text-center">
                  <div class="symbol symbol-20">
                    <div class="symbol-label bg-transparent">
                      <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                    </div>
                  </div>
                </b-col>
                <b-col :cols="6" class="font-size-h5">
                  {{ t.name }}
                </b-col>
              </b-row>
            </b-col>
            <!-- Second half -->
            <b-col :cols="12" :md="6">
              <b-row align-v="center" v-for="(t, i) in secondhalf(match)" v-bind:key="i">
                <b-col :cols="5" class="text-right font-size-h4 display-4 font-weight-bolder">
                  {{ t.score }}
                </b-col>
                <b-col :cols="1" class="text-center">
                  <div class="symbol symbol-20">
                    <div class="symbol-label bg-transparent">
                      <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                    </div>
                  </div>
                </b-col>
                <b-col :cols="6" class="font-size-h5">
                  {{ t.name }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- No Team -->
    <div v-if="noteam" class="card card-custom card-stretch mt-2">
      <div class="card-body px-4 pt-4 pb-2">
        <b-row no-gutters>
          <b-col v-for="(c, k) in match.noteams" v-bind:key="`${k}`" class="pr-2 mb-2" cols="*" :sm="4">
            <b-card no-body class="p-2 pool" v-on:click="openrate(c)" :class="pool(c)">
              <b-row align-v="center" no-gutters>
                <div class="flex-shrink-0 px-2" v-if="c.comment">
                  <b-badge variant="dark" class="extra">{{ c.comment }}</b-badge>
                </div>
                <b-col class="text-center">
                  <strong>{{ c.name }}</strong>
                  <div v-if="!match.ended">{{ c.rate }}</div>
                  <div v-else class="mt-2"><i class="far fa-check-circle" :class="win(c.no)" /></div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <!-- Team -->
    <div v-if="teams(match)" class="card card-custom card-stretch mt-2">
      <div class="card-body pt-2">
        <!-- Headers -->
        <div class="row align-items-center">
          <div class="col-4 p-4">
            <strong class="h6">Teams</strong>
          </div>
          <div class="col-8 pl-8 pr-5 d-none d-sm-block">
            <b-row no-gutters align-v="center">
              <b-col v-for="(c, j) in match.choices" v-bind:key="`${j}`" class="pr-2 text-center" cols="*" :sm="3">
                <strong>{{ c.name }}</strong>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- Teams -->
        <div v-for="(t, k) in match.teams" v-bind:key="`${k}`" class="row align-items-center">
          <div class="col-4 p-2">
            <b-row align-v="center" no-gutters>
              <b-col cols="auto">
                <div class="symbol symbol-30 symbol-light mr-3 flex-shrink-0">
                  <div class="symbol-label">
                    <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                  </div>
                </div>
              </b-col>
              <b-col cols="auto" v-if="match.teams.length === 2">
                <i class="fas fa-square mr-2 mb-2 icon-xs" v-bind:class="teamcolor(k)"></i>
              </b-col>
              <b-col class="">
                <strong>{{ t.name }}</strong>
              </b-col>
            </b-row>
          </div>
          <div class="col-8 pl-8 pr-5 mb-3">
            <b-row no-gutters align-v="center">
              <b-col v-for="(c, j) in t.choices" v-bind:key="`${k}-${j}`" class="pr-2" cols="*" :sm="3">
                <b-card no-body class="p-2 pool" v-on:click="openrate(c, t)" :class="pool(c, t)">
                  <b-row align-v="center" no-gutters>
                    <div class="flex-shrink-0" v-if="c.comment">
                      <b-badge variant="dark" class="extra">{{ c.comment }}</b-badge>
                    </div>
                    <b-col class="text-center">
                      <div class="d-block d-sm-none"><strong>{{ c.name }}</strong></div>
                      <div v-if="!match.ended">{{ c.rate }}</div>
                      <div v-else class="mt-1"><i class="far fa-check-circle" :class="win((t.no * 100) + c.no)" /></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <!-- Versus bar -->
          <div v-if="versus(k)" class="col-8 pl-8 offset-4 mb-3">
            <b-col class="pl-0 pr-2" cols="*" :sm="3">
              <b-progress class="bg-dark" height="5px">
                <b-progress-bar class="team2" :value="percteam2(match)" />
                <b-progress-bar class="team1" :value="percteam1(match)" />
              </b-progress>
            </b-col>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="dialogLogin" content-class="shadow" hide-header hide-footer centered>
      <div class="h4 text-center">เข้าสู่ระบบหรือลงทะเบียนเพื่อร่วมทายผล</div>
      <b-row align-h="center" class="mt-8">
        <b-col cols="auto">
          <b-btn variant="primary" to="/login">Login</b-btn>
          <b-btn variant="primary" class="ml-4" to="/register">Register</b-btn>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="setrate" content-class="shadow" hide-header hide-footer centered>
      <div class="mb-4">ทายผลด้วย wallet : {{ walletname }}</div>
      <!-- Select wallet -->
      <!-- <div class="mb-4">
        <b-form-select v-model="selected.wallet" :options="allwallets"></b-form-select>
      </div> -->
      <div class="d-flex mb-4">
        <div class="flex-shrink-1 mr-3" style="width: 50px" v-for="w in allwallets" v-bind:key="w.no">
          <div class="symbol symbol-50 symbol-dark game">
            <div class="symbol-label" @click="selected.wallet=w.value" v-bind:class="{'bg-white': selected.wallet === w.value }">
              <img :src="w.img" alt="" class="w-100 h-100" />
            </div>
          </div>
        </div>
      </div>
      <!-- Place point -->
      <div v-if="walletpoint > -1">
        <div class="h4">คะแนนที่สามารถใช้ได้ <strong>{{ walletpoint }}</strong> คะแนน</div>
        <b-row class="my-8" align-v="center">
          <b-col :cols="10">
            <div class="d-inline-flex align-items-center">
              <div class="symbol symbol-20 symbol-light mr-3 flex-shrink-0 text-center">
                <div class="symbol-label">
                  <img :src="selected.tourimg" alt="" class="h-100 w-100" />
                </div>
              </div>
              <div class="">{{ selected.tourname }}</div>
            </div>
            <div class="h5">{{ selected.matchname }}</div>
            <div class="h6">{{ selected.team }}&nbsp;<strong>{{ selected.title }}</strong></div>
          </b-col>
          <b-col :cols="2">
            <div v-if="selected.teamlogo" class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
              <div class="symbol-label">
                <img :src="selected.teamlogo" alt="" class="h-100 w-100" />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row no-gutters align-v="center">
          <b-col :cols="4" class="mr-4">จำนวนคะแนน</b-col>
          <b-col cols="auto" class="mr-2"><b-form-input v-model="setpoint" size="sm" style="width:90px" /></b-col>
          <b-col cols="auto">x {{ selected.rate }}</b-col>
        </b-row>
        <b-row no-gutters align-v="center" class="mt-4">
          <b-col :cols="4" class="mr-4">คะแนนที่ได้หากทายถูก</b-col>
          <b-col cols="auto"><strong>{{ calcpoint }} คะแนน</strong></b-col>
        </b-row>
        <b-row align-h="center" class="mt-8">
          <b-col cols="auto">
            <b-btn variant="primary" @click="submit">Submit</b-btn>
            <b-btn variant="light" class="ml-4" @click="cancel">Cancel</b-btn>
          </b-col>
        </b-row>
      </div>
      <!-- Get starting point -->
      <div v-else>
        <div class="h4 text-center">รับแต้มครั้งแรกสำหรับการทายผล</div>
        <div class="h6 text-center">และรับแต้มทายผลเพิ่มที่แถบผู้ใช้ (คลิกที่ชื่อด้านบน)</div>
        <b-row align-h="center" class="mt-8">
          <b-col cols="auto">
            <b-btn variant="primary" @click="getpoint">Get Point</b-btn>
            <b-btn variant="primary" class="ml-4" @click="cancel">Cancel</b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Carousel from "@/view/content/Carousel.vue";
import { UPDATE_WALLET_POINT } from "@/core/services/store/profile.module";

export default {
  name: "dashboard",
  components: {
    Carousel
  },
  mounted() {
    this.recvMatch()
  },
  methods: {
    recvMatch() {
      this.loading = true

      this.axios.get(`/matches/${this.matchID}`).then(
        ({ data }) => {
          this.match = data.match
          this.points = data.points

          var sp = {}
          for (var k in this.points) {
            for (var kk in this.points[k]) {
              for (var kkk in this.points[k][kk]) {
                sp[`${k}${kkk}`] = this.points[k][kk][kkk]
              }
            }
          }

          this.selectPools = sp
        }
      ).finally(() => {
        this.loading = false
      })
    },
    date (m) {
      if (m == null) {
        return ''
      }

      return dayjs(m).format('DD/MM/YYYY')
    },
    time (m) {
      if (m == null) {
        return ''
      }

      return dayjs(m).format('HH:mm')
    },
    playing(m) {
      return dayjs() > dayjs(m.plan)
    },
    teams (match) {
      return match.teams != null && match.teams.length > 0
    },
    modevs(m) {
      return m.teams != null && m.teams.length === 2
    },
    modeffa(m) {
      return m.teams != null && m.teams.length > 2
    },
    chk(c) {
      return c.checked != null && c.checked ? 'success' : 'secondary text-dark-50'
    },
    win(c) {
      return this.match.win != null && this.match.win[`c${c}`] ? 'correct' : 'incorrect'
    },
    firsthalf (m) {
      var num = (m.teams || []).length
      if (num < 5) {
        return m.teams
      }

      if (num === 0) {
        return []
      }

      var num = Math.ceil(num * 0.5)
      return m.teams.slice(0, num)
    },
    secondhalf (m) {
      var num = (m.teams || []).length
      if (num < 5) {
        return []
      }

      var half = Math.ceil(num * 0.5)
      return m.teams.slice(half, num)
    },
    openrate (c, t) {
      if (this.match != null && this.match.ended) {
        return
      }

      // Guest
      if (this.guest) {
        this.dialogLogin = true
        return
      }

      var p = 0

      if (t != null) {
        this.selected.choice = p = (t.no * 100) + c.no
        this.selected.team = t.name
        this.selected.teamlogo = t.img
      } else {
        this.selected.choice = p = c.no
        this.selected.team = ''
        this.selected.teamlogo = ''
      }

      this.selected.tourimg = this.match.tour.img
      this.selected.tourname = this.match.tour.name
      this.selected.matchname = this.match.name

      this.selected.title = c.name
      this.selected.match = this.match.no
      this.selected.rate = c.rate

      this.selected.wallet = 0
      this.selected.wallets = this.match.wallets || []

      if (this.selected.wallets != null && this.selected.wallets[0]) {
        this.selected.wallet = this.selected.wallets[0]
      }

      var mkey = `m${this.match.no}`
      var wkey = `w${this.selected.wallet}`
      var ckey = `c${p}`

      if (this.points[mkey] != null && this.points[mkey][wkey] != null && this.points[mkey][wkey][ckey] != null) {
        this.setpoint = this.points[mkey][wkey][ckey]
      } else {
        this.setpoint = 0
      }

      this.setrate = true
    },
    submit () {
      if (this.setpoint < 0) {
        return
      }

      var p = this.selected.choice
      var m = this.selected.match
      var w = this.selected.wallet

      if (this.points[`m${m}`] != null && this.points[`m${m}`][`w${w}`] != null && this.points[`m${m}`][`w${w}`][`c${p}`] != null) {
        if (this.points[`m${m}`][`w${w}`][`c${p}`] == this.setpoint) {
          return
        }
      }

      var params = {
        match: m,
        choice: p,
        pts: this.setpoint
      }

      this.axios.post(`/wallets/${this.wallet}/placepoint`, params).then(
        ({ data }) => {
          var c = data.choice
          var t = data.match

          if (this.points[`m${t}`] == null) {
            this.$set(this.points, `m${t}`, {})
          }

          if (this.points[`m${t}`][`w${w}`] == null) {
            this.$set(this.points[`m${t}`], `w${w}` , {})
          }

          this.$set(this.points[`m${t}`][`w${w}`], `c${c}`, data.pts)
          this.$set(this.selectPools, `m${t}c${c}`, data.pts)
          this.cancel()

          this.$store.dispatch(UPDATE_WALLET_POINT, { no: data.wallet, points: data.ptsleft })
        }
      )
    },
    cancel () {
      this.setrate = false
    },
    versus (key) {
      return key === 0 && this.match.teams.length === 2
    },
    teamcolor (key) {
      if (key === 0) {
        return 'team1'
      } else {
        return 'team2'
      }
    },
    percteam1 (match) {
      return match.perc || 0
    },
    percteam2 (match) {
      return match.perc != null ? 100 - match.perc : 0
    },
    pool (c, t) {
      var p = 0

      if (t != null) {
        p = (t.no * 100) + c.no
      } else {
        p = c.no
      }

      var k = this.selectPools[`m${this.match.no}c${p}`]
      return k != null && k > 0 ? 'selected' : ''
    },
    getpoint () {
      this.axios.post(`/wallets/${this.wallets.id}/getpoints`).then(
        ({ data }) => {
          this.$store.dispatch(UPDATE_WALLET_POINT, {
            no: data.no,
            points: data.points,
            time: data.nextgen
          })
        }
      )
    }
  },
  computed: {
    timezone () {
      return dayjs().format('Z')
    },
    guest () {
      return this.user.name == null
    },
    user () {
      return this.$store.getters['currentUser'] || {}
    },
    calcpoint () {
      return this.selected.rate * this.setpoint
    },
    wallets() {
      return this.$store.getters['currentWallet'].find(v => v.no === this.selected.wallet)
    },
    walletname() {
      return this.wallets != null ? this.wallets.name : ''
    },
    walletpoint() {
      return this.wallets != null ? this.wallets.points : 0
    },
    matchID() {
      return this.$route.params.match
    },
    allwallets() {
      return this.$store.getters['currentWallet'].filter(v => this.selected.wallets.includes(v.no)).map(v => {
        return {
          value: v.no,
          text: v.name,
          img: v.img
        }
      })
    },
    noteam() {
      return this.match != null && this.match.noteams != null && this.match.noteams.length > 0
    },
    embed() {
      return this.match.embed != null
    }
  },
  watch: {
    wallet() {
      this.recvMatch()
    }
  },
  data() {
    return {
      match: {
        game: {},
        tour: {},
        teams: []
      },
      banners: [],
      points: {},
      setrate: false,
      selected: {
        tourimg: '',
        tourname: '',
        matchname: '',
        match: 0,
        wallet: 0,
        choice: 0,
        title: '',
        team: '',
        teamlogo: '',
        rate: 0,
        wallets: []
      },
      setpoint: 0,
      loading: true,
      selectPools: {},
      dialogLogin: false
    }
  }
};
</script>

<style lang="scss" scoped>
.game {
  cursor: pointer;
}

.active {
  background-color: #39374a;
}

.pool {
  background-color: #1d212a;
  border-color: #292f3c;
}

.pool.selected {
  border-color: #005200;
  background-color: #194000;
}

.pool:hover {
  cursor: pointer;
  border-color: #a9a9a9;
  background-color: #242530;
}

.team1 {
  background-color: #c90707;
  color: #c90707;
}

.team2 {
  background-color: #07c927;
  color: #07c927;
}

.incorrect {
  color: transparent;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.extra {
  background-color: #4c4c4c;
}
</style>